<template>
  <div class="register-step register-payment">
    <div class="auth-form-heading">
      <p class="auth-form-title">PAGAMENTO</p>
    </div>
    <div class="auth-form-wrapper">
      <FormulateForm action="" class="auth-form register-payment" v-model="values" @submit="handleFinalRegister">
        <div class="auth-faqs-wrapper">
          <div class="auth-faqs-item faqs-item active">
            <button type="button" class="faqs-item-btn auth-faqs-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
              <p>Opção de Pagamento</p>
              <div class="auth-faqs-item-btn-icon">
                <chevronDown/>
              </div>
            </button>
            <div class="auth-faqs-item-content-wrapper">
              <div class="auth-faqs-item-content faqs-content">
                <div class="auth-faqs-radio-wrapper" v-for="(item,index) in subscriptions" :key="index">
                  <label>
                    <input type="radio" name="payment-option" value="1" checked>
                    <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                      <div class="flex-1 d-md-flex justify-content-between align-items-center">
                        <p class="auth-faqs-radio-btn-title">{{ item.name }}</p>
                        <div class="d-flex justify-content-start align-items-center">
                          <p class="auth-faqs-radio-value">{{ item.normalPrice }} <span>(5,09€/mês)</span></p>
                          <p class="auth-faqs-radio-discount" v-if="!!item.discount_ammount">{{ item.discount_ammount }}</p>
                        </div>
                      </div>
                      <div class="auth-faqs-radio-checkbox"></div>
                    </div>
                  </label>
                </div>
                <!--<div class="add-voucher-wrapper">
                  <button class="add-voucher-btn d-flex justify-content-start align-items-center" @click="openVoucherForm">
                    <p>Adicionar Voucher Promocional</p>
                    <div class="add-voucher-btn-icon">
                      <plus/>
                    </div>
                  </button>
                  <form class="add-voucher-form">
                    <div class="d-flex justify-content-start align-items-center">
                      <div class="add-voucher-input">
                        <input type="text">
                      </div>
                      <button type="button" class="generic-btn red-gradient smaller">Validar</button>
                    </div>
                  </form>
                </div>-->
              </div>
            </div>
          </div>
          <div class="auth-faqs-item faqs-item">
            <button type="button" class="faqs-item-btn auth-faqs-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
              <p>Método de Pagamento</p>
              <div class="auth-faqs-item-btn-icon">
                <chevronDown/>
              </div>
            </button>
            <div class="auth-faqs-item-content-wrapper">
              <div class="auth-faqs-item-content faqs-content">
                <div class="auth-faqs-radio-wrapper">
                  <label>
                    <input type="radio" name="payment-method" value="mb">
                    <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                      <div class="flex-1 d-flex justify-content-start align-items-center">
                        <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                          <img src="@/assets/img/multibanco-logo.png" alt="">
                        </div>
                        <p class="auth-faqs-radio-btn-title">Multibanco</p>
                      </div>
                      <div class="auth-faqs-radio-checkbox"></div>
                    </div>
                  </label>
                </div>
                <div class="auth-faqs-radio-wrapper">
                  <label>
                    <input type="radio" name="payment-method" value="visa">
                    <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                      <div class="flex-1 d-flex justify-content-start align-items-center">
                        <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                          <img src="@/assets/img/visa.png" alt="">
                        </div>
                        <p class="auth-faqs-radio-btn-title">Visa</p>
                      </div>
                      <div class="auth-faqs-radio-checkbox"></div>
                    </div>
                  </label>
                </div>
                <div class="auth-faqs-radio-wrapper">
                  <label>
                    <input type="radio" name="payment-method" value="mbWay">
                    <div class="auth-faqs-radio-btn d-flex justify-content-lg-between align-items-center">
                      <div class="flex-1 d-flex justify-content-start align-items-center">
                        <div class="auth-faqs-radio-btn-image d-flex justify-content-center align-items-center">
                          <img src="@/assets/img/mb-way.png" alt="">
                        </div>
                        <p class="auth-faqs-radio-btn-title">MB Way</p>
                      </div>
                      <div class="auth-faqs-radio-checkbox"></div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="auth-faqs-item faqs-item">
            <button type="button" class="faqs-item-btn auth-faqs-item-btn d-flex justify-content-between align-items-center" @click="toggleAccordion">
              <p>Dados de Faturação</p>
              <div class="auth-faqs-item-btn-icon">
                <chevronDown/>
              </div>
            </button>
            <div class="auth-faqs-item-content-wrapper">
              <div class="auth-faqs-item-content faqs-content">
                <div class="box-form-group">
                  <div class="row box-form-row">
                    <div class="col-12 col-lg-6 box-form-column">
                      <div class="form-field">
                        <FormulateInput type="text" name="first-name" v-model="billingFirstName" validation="required" placeholder="Insira o seu primeiro nome" label="Primeiro Nome" />
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 box-form-column">
                      <div class="form-field">
                        <FormulateInput type="text" name="last-name" v-model="billingLastName" validation="required" placeholder="Insira o seu último nome" label="Último Nome" />
                      </div>
                    </div>
                    <div class="col-12 box-form-column">
                      <div class="form-field">
                        <FormulateInput type="text" name="address" v-model="billingAddress" validation="required" placeholder="Insira a sua morada" label="Morada" />
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 box-form-column">
                      <div class="form-field">
                        <FormulateInput type="text" name="zip-code" v-model="billingZipCode" validation="required" placeholder="0000-000" label="Código-Postal" />
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 box-form-column">
                      <div class="form-field">
                        <FormulateInput type="text" name="city" v-model="billingCity" validation="required" placeholder="Insira a sua cidade" label="Cidade" />
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 box-form-column">
                      <div class="form-field">
                        <FormulateInput type="select" name="country" v-model="billingCountry" validation="required" placeholder="Selecione o seu país" label="País" :options="this.$root.countries" />
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 box-form-column">
                      <div class="form-field">
                        <FormulateInput type="text" name="nif" v-model="billingNif" validation="required" placeholder="Insira o seu NIF" label="NIF" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-message-response-wrapper message-error" v-if="showMessage && !submitted" v-html="formResponseMessage"></div>
        <button type="submit" class="generic-btn grey-gradient auth-form-submit">Finalizar Subscrição</button>
      </FormulateForm>
    </div>
    <div class="payment-images">
      <img class="reduniq" src="@/assets/img/reduniq.webp" alt="">
      <div class=" d-flex justify-content-center align-items-center">
        <img class="visa" src="@/assets/img/vbyvisa_blu.png" alt="">
        <img class="mastercard" src="@/assets/img/mastercard securecode.gif" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import lock from "@/components/svg/lock2.vue";
import lockOpen from "@/components/svg/lock2-open.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import plus from "@/components/svg/plus.vue";
import {EventBus} from "@/event-bus";
import axios from "axios";
import qs from "qs";

export default {
  name: "auth",
  data() {
    return {
      values: {},
      errors: [],
      submitted: false,
      billingFirstName: '',
      billingLastName: '',
      billingAddress: '',
      billingZipCode: '',
      billingCity: '',
      billingCountry: '',
      billingNif: '',
      showMessage: false,
      formResponseMessage: '',
      subscriptions: [],
    };
  },
  components : {
    lock,
    lockOpen,
    chevronDown,
    plus,
  },
  methods: {
    toggleAccordion (e) {
      let clickedItem = e.currentTarget.closest('.auth-faqs-item');
      let items = Array.from(document.querySelectorAll('.auth-faqs-item'));

      if ( clickedItem.classList.contains('active') ) {
        clickedItem.classList.remove('active');
      } else {
        items.forEach(node => {
          node.classList.remove('active');
        });

        clickedItem.classList.add('active');
      }
    },
    openVoucherForm: function(e) {
      e.preventDefault();
      let wrapper = document.querySelector('.add-voucher-wrapper');

      if ( wrapper.classList.contains('active') ) {
        wrapper.classList.remove('active');
      } else {
        wrapper.classList.add('active');
      }
    },
    resetRegisterHandler() {
      this.$root.registerFormInfo = [];
    },
    handleFinalRegister: function() {
      let paymentOption = document.querySelector('input[name="payment-option"]:checked') !== null;
      let paymentMethod = document.querySelector('input[name="payment-method"]:checked') !== null;

      if ( paymentOption && paymentMethod ) {

        let paymentOptionValue = document.querySelector('input[name="payment-option"]:checked').value;
        let paymentMethodValue = document.querySelector('input[name="payment-method"]:checked').value;

        let paymentsInfo = {
          option: paymentOptionValue,
          method: paymentMethodValue,
          billingInfo: {
            firstName: this.billingFirstName,
            lastName: this.billingLastName,
            address: this.billingAddress,
            zipCode: this.billingZipCode,
            city: this.billingCity,
            country: this.billingCountry,
            nif: this.billingNif,
          }
        };

        this.$root.registerFormInfo.payments = paymentsInfo;

        axios
            .post(process.env.VUE_APP_API_URL + 'customer/register', qs.stringify(this.$root.registerFormInfo))
            .then((response) => {
              if (response.data.success) {
                this.resetRegisterHandler();
                this.$root.loggedIn = true;
                this.$root.authKey = response.data.model.auth_key;

                this.$cookies.set("loggedIn",true,60 * 60 * 4);
                this.$cookies.set("authKey",response.data.model.auth_key,60 * 60 * 4);

                this.$root.userInfo = response.data.model;
                this.$router.push({ name: 'dashboard' });
              }
            })
            .catch((e) => {
              if ( e.response.status >= 500 ) {
                this.errors.push(e);
                this.showMessage = true;
                this.formResponseMessage = 'An error occurred.';
              } else {
                let errorMessage = '';

                let errors = e.response.data.errors;
                errors = Object.values(errors);

                for( var i = 0; i < errors.length; ++i ) {
                  errorMessage += '<p>' + errors[i].toString() + '</p>';
                }

                this.showMessage = true;
                this.formResponseMessage = errorMessage;
              }
            });
      } else {
        if ( paymentOption == false ) {
          this.showMessage = true;
          this.formResponseMessage = 'You have to select a payment option';
        }

        if ( paymentMethod == false ) {
          this.showMessage = true;
          this.formResponseMessage = 'You have to select a payment method';
        }
      }
    }
  },
  created() {
    axios
        .get(process.env.VUE_APP_API_URL + 'order/subscription')
        .then((response) => {
          this.subscriptions = response.data.model;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  }
}
</script>