<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="21" viewBox="0 0 16 21">
    <defs>
      <path id="p3o64682ia" d="M5.768 7c-.01-.438-.017-.816-.018-1.133v-.352c.001-.269.007-.477.018-.626.024-.356.102-.644.232-.865L3.591 2.64C4.434 1.07 6.093 0 8 0c2.76 0 5 2.24 5 5v2h1c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V9c0-1.1.9-2 2-2zM8 16c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-181 -275) translate(20 242) translate(157 32) translate(4 1)">
              <mask id="vm7cq4yx9b" fill="#fff">
                <use xlink:href="#p3o64682ia"/>
              </mask>
              <use fill="#222221" xlink:href="#p3o64682ia"/>
              <path fill="#FFF" d="M11.1 7H4.9V5c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" mask="url(#vm7cq4yx9b)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'lock-open'
}
</script>
